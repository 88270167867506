import React, {Component} from 'react';
import Text from '../../../atoms/Text/Text';
import Hero from '../Hero/Hero';
import MiniMenu from '../MiniMenu/MiniMenu';
import Image from '../Image/Image';
import Video from '../Video/Video';
import ImageCollection from '../ImageCollection/ImageCollection';
import ImageTextCollection from '../ImageTextCollection/ImageTextCollection';
import TimelineParagraph from '../TimelineParagraph/TimelineParagraph';
import Entity from '../../../../drupal/Entity';
import Include from '../../../../drupal/Include';
import ThirdPartyEmbed from '../ThirdPartyEmbed/ThirdPartyEmbed';
import TwoColumnSection from '../TwoColumnSection/TwoColumnSection';
import MediaText from '../MediaText/MediaText';

export default class Paragraph extends Component  {

  build = (item) => {
    if(item instanceof Entity || item instanceof Include) {
      switch (Entity.parseTypeBundle(item.get('type')).bundle) {
        case 'text':
          return <Text key={item.get('id')} text={item.get('field_body').processed} />;
        case 'hero':
          return <Hero key={item.get('id')} drupal={this.props.drupal} items={item.get('field_items')} size={item.get('field_size')} />;
        case 'mini_menu':
          return <MiniMenu key={item.get('id')} title={item.get('field_title')} links={item.get('field_links')} text={item.get('field_text')?.get('field_body').processed} headline={item.get('field_text_headline')} />;
        case 'image':
          return <Image key={item.get('id')} title={item.get('field_title')} subtitle={item.get('field_subtitle')} media={item.get('field_image')} caption={item.get('field_caption')} />;
        case 'video':
          return <Video key={item.get('id')} title={item.get('field_title')} subtitle={item.get('field_subtitle')} media={item.get('field_video')} drupal={this.props.drupal} />
        case 'image_collection':
          return <ImageCollection key={item.get('id')} title={item.get('field_title')} subtitle={item.get('field_subtitle')} items={item.get('field_image_paragraphs')} />
        case 'image_and_text_collection':
          return <ImageTextCollection key={item.get('id')} title={item.get('field_title')} subtitle={item.get('field_subtitle')} items={item.get('field_images_and_text')} />;
        case 'timeline':
          return <TimelineParagraph key={item.get('id')} title={item.get('field_title')} subtitle={item.get('field_subtitle')} items={item.get('field_timeline_items')} />;
        case 'third_party_embed':
          return <ThirdPartyEmbed key={item.get('id')} itemId={item.get('id')} embedCode={item.get('field_embed_code')} />
        case 'two_column_section':
          return <TwoColumnSection key={item.get('id')} item={item} drupal={this.props.drupal} />
        case 'media_and_text':
          return <MediaText key={item.get('id')} item={item} drupal={this.props.drupal} />

        default:
          return null;
      }
    }

    return null;
  }

  buildList = () => {
    // Render the content
    let content = [];

    if (this.props.content !== undefined) {
      if (this.props.content instanceof Array) {
        this.props.content.forEach(element => {
          content.push(this.build(element));
        })
      }
      else {
        content.push(this.build(this.props.content));
      }
    }
    return content;
  }

  render = () => {
    return this.buildList();
  }

}